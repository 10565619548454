import * as bootstrap from "bootstrap";

import jquery from "jquery";

window.$ = window.jQuery = jquery;
if (!process.env.BUILD_DEPLOY) {
    // get warnings for using deprecated jquery features
    require("jquery-migrate");
}

$(document)
    .ready(function () {
        // Make my email visible.
        // http://www.electriceasel.com/tips-tricks/using-jquery-to-hide-email
        $(".workemail")
            .each(function () {
                $(this)
                    .html(function () {
                        var e = "leggert";
                        var a = "@";
                        var d = "mozilla";
                        var c = ".com";
                        var h = "mailto:" + e + a + d + c;
                        $(this)
                            .attr("href", h);
                        return e + a + d + c;
                    });
            });
        $(".homeemail")
            .each(function () {
                $(this)
                    .html(function () {
                        var e = "lars";
                        var a = "@";
                        var d = "eggert";
                        var c = ".org";
                        var h = "mailto:" + e + a + d + c;
                        $(this)
                            .attr("href", h);
                        return e + a + d + c;
                    });
            });

        // // Thank users for using IPv6.
        // var r = new RegExp("(meter|power)/*", "i");
        // //console.log(window.location.href);
        // var path = (r.test(window.location.href) ? "../" : "");
        // //console.log(path + "ipv6.json");
        // $.getJSON(path + "ipv6.json", function (data) {
        //     if (data.ipv6) {
        //         $(".ipv6")
        //             .each(function () {
        //                 $(this)
        //                     .html("Thank you for using IPv6.");
        //             });
        //     }
        // });
    });
